import React from "react"
import PropTypes from "prop-types"

import Text from "./text"

const WordBreak = (props) => {
  const { children, as, ...restProps } = props

  return (
    <Text
      as={as}
      {...restProps}
      sx={{
        hyphens: "none",
        wordBreak: "break-word",
      }}
    >
      {children}
    </Text>
  )
}

WordBreak.propTypes = {
  as: PropTypes.string,
}

WordBreak.defaultProps = {
  as: "span",
}

export default WordBreak
